import React from 'react'
import styled from 'styled-components'
import StyledLink from '../components/styles/StyledLink'

import SEO from '../components/SEO'

const Container = styled.div`
  margin-top: ${p => p.theme.marginToTop};
  margin-bottom: 120px;
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
`

const TopHeading = styled.h1`
  font-size: 38px;
  font-family: Montserrat, sans-serif;
  color: ${p => p.theme.textColorDark};
  font-weight: 600;
  margin-top: ${p => p.theme.marginToTop};
  margin-bottom: 60px;
  line-height: 1.2;
`

const Paragraph = styled.p`
  margin-bottom: 26px;
`

const List = styled.ul``

const ListItem = styled.li`
  display: flex;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`

const ListItemLeft = styled.div`
  width: 300px;
  padding-left: 24px;
  @media (max-width: 500px) {
    width: auto;
    padding-left: 0;
  }
`

const ListItemRight = styled.div`
  @media (max-width: 500px) {
    padding-left: 0 !important;
    margin-bottom: 10px;
  }
`

const ListHeading = styled.div`
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: ${p => p.theme.textColorDark};
  letter-spacing: 1.2px;
  margin-bottom: 12px;
  margin-top: 38px;
`

const Versand = () => {
  return (
    <>
      <SEO title="Versand" description="Versandkostenfrei schon ab 140 € - oder 24 Flaschen." />
      <Container>
        <TopHeading>Versand&shy;informationen</TopHeading>
        <Paragraph>
          Es gilt eine <strong>Mindestbestellmenge von 6 Flaschen.</strong> Aus versandtechnischen Gründen empfehlen wir
          Ihnen Bestellungen, die <strong>durch 6 teilbar</strong> sind.
          <br />
          Kleinere Kartongrößen nur auf Anfrage.
        </Paragraph>
        <ListHeading>VERSAND NACH ÖSTERREICH</ListHeading>
        <Paragraph>
          Die Zustellung ist innerhalb Österreichs{' '}
          <strong>ab einem Bestellwert von 140 € bzw. 24 Flaschen kostenlos</strong>. Ansonsten verrechnen wir innerhalb
          Österreichs folgende Versandkosten:
        </Paragraph>
        <Paragraph>
          <List>
            <ListItem>
              <ListItemLeft>6 Flaschen:</ListItemLeft>{' '}
              <ListItemRight style={{ paddingLeft: '9px' }}>
                <strong>8,- €</strong>
              </ListItemRight>
            </ListItem>
            <ListItem>
              <ListItemLeft>Bis inklusive 12 Flaschen:</ListItemLeft>{' '}
              <ListItemRight>
                <strong>10,- €</strong>
              </ListItemRight>
            </ListItem>
            <ListItem>
              <ListItemLeft>Bis inklusive 18 Flaschen:</ListItemLeft>{' '}
              <ListItemRight>
                <strong>15,- €</strong>
              </ListItemRight>
            </ListItem>
            <ListItem>
              <ListItemLeft>Bis inklusive 23 Flaschen:</ListItemLeft>{' '}
              <ListItemRight>
                <strong>18,- €</strong>
              </ListItemRight>
            </ListItem>
            <ListItem>
              <ListItemLeft>Ab 24 Flaschen bzw. ab 140,- €:</ListItemLeft>{' '}
              <ListItemRight>
                <strong>frei Haus</strong>
              </ListItemRight>
            </ListItem>
            <ListItem style={{ marginTop: '20px' }}>
              <ListItemLeft>Lieferzeit in Österreich:</ListItemLeft>{' '}
              <ListItemRight>
                <strong>3-5 Werktage</strong>
              </ListItemRight>
            </ListItem>
          </List>
        </Paragraph>

        <ListHeading>VERSAND NACH DEUTSCHLAND</ListHeading>
        <Paragraph>Nach Deutschland verrechnen wir folgende Versandkosten:</Paragraph>
        <Paragraph>
          <List>
            <ListItem>
              <ListItemLeft>6 Flaschen:</ListItemLeft>{' '}
              <ListItemRight>
                <strong>15,- €</strong>
              </ListItemRight>
            </ListItem>
            <ListItem>
              <ListItemLeft>Bis inklusive 12 Flaschen:</ListItemLeft>{' '}
              <ListItemRight>
                <strong>18,- €</strong>
              </ListItemRight>
            </ListItem>
            <ListItem>
              <ListItemLeft>Bis inklusive 18 Flaschen:</ListItemLeft>{' '}
              <ListItemRight>
                <strong>33,- €</strong>
              </ListItemRight>
            </ListItem>
            <ListItem>
              <ListItemLeft>Bis inklusive 24 Flaschen:</ListItemLeft>{' '}
              <ListItemRight>
                <strong>36,- €</strong>
              </ListItemRight>
            </ListItem>
          </List>
        </Paragraph>
        <Paragraph>
          Für größere Lieferungen fordern Sie bitte ein spezielles Angebot an oder erkundigen Sie sich nach unserem
          Lieferplan per E-Mail unter{' '}
          <StyledLink style={{ whiteSpace: 'nowrap' }} as="a" href="mailto:office@weingut-goeschl.at">
            office@weingut-goeschl.at
          </StyledLink>
          .
        </Paragraph>
        <ListHeading>VERSAND INNERHALB DER EU</ListHeading>
        <Paragraph>
          Lieferungen innerhalb der EU sind möglich. Bestellungen werden per E-Mail unter{' '}
          <StyledLink style={{ whiteSpace: 'nowrap' }} as="a" href="mailto:office@weingut-goeschl.at">
            office@weingut-goeschl.at
          </StyledLink>{' '}
          angenommen und bearbeitet.
        </Paragraph>
        <ListHeading>SELBSTABHOLUNG</ListHeading>
        <Paragraph>
          Selbstabholungen ab Hof sind natürlich nach vorheriger Anmeldung möglich! Für etwaige Fragen stehen wir Ihnen
          gerne unter{' '}
          <StyledLink style={{ whiteSpace: 'nowrap' }} as="a" href="mailto:office@weingut-goeschl.at">
            office@weingut-goeschl.at
          </StyledLink>{' '}
          oder via{' '}
          <StyledLink style={{ whiteSpace: 'nowrap' }} as="a" href="tel:+436644530988">
            +43 664 4530988
          </StyledLink>{' '}
          zur Verfügung.
        </Paragraph>
        <ListHeading>BEZAHLMÖGLICHKEITEN</ListHeading>
        <Paragraph>
          Im Online Shop:
          <div style={{ paddingBottom: '12px' }} />
          <List>
            <ListItem>
              <ListItemLeft>
                - Vorauskasse
                <br />
                <div style={{ paddingLeft: '9px', marginBottom: '10px' }}>
                  Weingut Göschl
                  <br />
                  IBAN: AT10 3300 0000 0231 2718
                  <br />
                  BIC: RLBBAT2E
                </div>
              </ListItemLeft>
            </ListItem>
            <ListItem>
              <ListItemLeft>- Kreditkarte</ListItemLeft>
            </ListItem>
          </List>
        </Paragraph>
        <Paragraph>
          Ab Hof:
          <List>
            <ListItem>
              <ListItemLeft>
                <div style={{ paddingBottom: '12px' }} />
                <div style={{ marginBottom: '10px' }}>- Barzahlung</div>- Bankomat/Kreditkarte
              </ListItemLeft>
            </ListItem>
          </List>
        </Paragraph>
      </Container>
    </>
  )
}

export default Versand
